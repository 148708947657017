import { Grid, styled } from "@mui/material";
import React from "react";
import TextSize from "component/commons/typography/TextSize";
import TextLabel from "./TextLabel";
import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import { EditorProvider, useCurrentEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import {
  Code,
  FormatAlignCenter,
  FormatAlignJustify,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatQuote,
  FormatStrikethrough,
  HorizontalRule,
  KeyboardReturn,
  Redo,
  Undo,
} from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import TextAlign from "@tiptap/extension-text-align";

const EditorSection = styled("div")(() => ({
  display: "inline-flex",
  marginRight: "1rem",
  alignSelf: "center",
}));

const EditorButton = styled("button")(({ theme, isActive }) => ({
  backgroundColor: isActive ? theme.palette.action.active : theme.palette.background.primary,
  color: isActive ? theme.palette.background.secondary : theme.palette.text.primary,
  border: "none",
}));

const MenuBar = () => {
  const { editor } = useCurrentEditor();
  const theme = useTheme();

  if (!editor) {
    return null;
  }

  return (
    <div>
      <EditorSection>
        <EditorButton
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          isActive={editor.isActive("bold")}
        >
          <FormatBold />
        </EditorButton>
        <EditorButton
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          isActive={editor.isActive("italic")}
        >
          <FormatItalic />
        </EditorButton>
        <EditorButton
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          isActive={editor.isActive("strike")}
        >
          <FormatStrikethrough />
        </EditorButton>
        <EditorButton
          onClick={() => editor.chain().focus().toggleCode().run()}
          disabled={!editor.can().chain().focus().toggleCode().run()}
          isActive={editor.isActive("code")}
        >
          <Code />
        </EditorButton>
        <EditorButton
          onClick={() => editor.chain().focus().setColor(theme.palette.action.active).run()}
          isActive={editor.isActive("textStyle", { color: theme.palette.action.active })}
        >
          gold
        </EditorButton>
      </EditorSection>

      <EditorSection>
        <EditorButton
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          isActive={editor.isActive("heading", { level: 1 })}
        >
          h1
        </EditorButton>
        <EditorButton
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          isActive={editor.isActive("heading", { level: 2 })}
        >
          h2
        </EditorButton>
        <EditorButton
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          isActive={editor.isActive("heading", { level: 3 })}
        >
          h3
        </EditorButton>
        <EditorButton
          onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
          isActive={editor.isActive("heading", { level: 4 })}
        >
          h4
        </EditorButton>
        <EditorButton
          onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
          isActive={editor.isActive("heading", { level: 5 })}
        >
          h5
        </EditorButton>
        <EditorButton
          onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
          isActive={editor.isActive("heading", { level: 6 })}
        >
          h6
        </EditorButton>
      </EditorSection>

      <EditorSection>
        <EditorButton
          onClick={() => editor.chain().focus().setTextAlign("justify").run()}
          isActive={editor.isActive({ textAlign: "justify" })}
        >
          <FormatAlignJustify/>
        </EditorButton>
        <EditorButton
          onClick={() => editor.chain().focus().setTextAlign("left").run()}
          isActive={editor.isActive({ textAlign: "left" })}
        >
          <FormatAlignLeft />
        </EditorButton>
        <EditorButton
          onClick={() => editor.chain().focus().setTextAlign("center").run()}
          isActive={editor.isActive({ textAlign: "center" })}
        >
          <FormatAlignCenter />
        </EditorButton>
        <EditorButton
          onClick={() => editor.chain().focus().setTextAlign("right").run()}
          isActive={editor.isActive({ textAlign: "right" })}
        >
          <FormatAlignRight />
        </EditorButton>
      </EditorSection>

      <EditorSection>
        <EditorButton
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          isActive={editor.isActive("bulletList")}
        >
          <FormatListBulleted />
        </EditorButton>
        <EditorButton
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          isActive={editor.isActive("orderedList")}
        >
          <FormatListNumbered />
        </EditorButton>
        <EditorButton
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          isActive={editor.isActive("blockquote")}
        >
          <FormatQuote />
        </EditorButton>
        <EditorButton onClick={() => editor.chain().focus().setHorizontalRule().run()}>
          <HorizontalRule />
        </EditorButton>
        <EditorButton onClick={() => editor.chain().focus().setHardBreak().run()}>
          <KeyboardReturn />
        </EditorButton>
      </EditorSection>

      <EditorSection>
        <EditorButton onClick={() => editor.chain().focus().unsetAllMarks().run()}>
          clear
        </EditorButton>
      </EditorSection>

      <EditorSection>
        <EditorButton
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().chain().focus().undo().run()}
        >
          <Undo />
        </EditorButton>
        <EditorButton
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().chain().focus().redo().run()}
        >
          <Redo />
        </EditorButton>
      </EditorSection>
    </div>
  );
};

const extensions = [
  Color.configure({ types: [TextStyle.name, ListItem.name] }),
  TextStyle.configure({ types: [ListItem.name] }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
    },
  }),
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
];

export default function AdminTextArea({ data, value, setValue }) {
  const onChange = (event) => {
    setValue(data.key, event.editor.getHTML());
  };

  return (
    <Grid item xs={12}>
      <TextLabel Tag="h4" size={TextSize.SMALL}>
        {data.label}
      </TextLabel>
      <EditorProvider
        slotBefore={<MenuBar />}
        extensions={extensions}
        content={value}
        onUpdate={onChange}
      ></EditorProvider>
    </Grid>
  );
}
